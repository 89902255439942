<template>
  <div class="my-8 mx-6 w-2/4 relative shadow-primary p-5 rounded-lg">
    <div class="flex justify-between items-center text-lg font-bold mb-4">
      <span>
        {{ t('change_profile.personal_details') }}
      </span>
      <button
        class="underline text-primary text-xs"
        @click="onProfileSave">
        {{ t('change_profile.save_details') }}
      </button>
    </div>

    <form class=" my-6 text-left">
      <label class="text-black text-sm font-bold mt-6">
        {{ t('change_profile.account_name') }}
      </label>
      <input
        class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text"
        v-model="userDetails.name">

      <label class="text-black text-sm font-bold mt-6">
        {{ t('change_profile.email_address') }}
      </label>
      <input
        class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="email"
        v-model="userDetails.email">
<!---->
      <label class="text-black text-sm font-bold mt-6">
        {{ t('change_profile.billing_address') }}
      </label>

      <div class="grid items-center" style="grid-template-columns: 150px auto">
        <label>
          {{ t('change_profile.first_name') }}
        </label>
        <input
          class="h-10 border rounded-xl p-2 my-2 border-gray-200" type="text"
          v-model="userDetails.firstName">
      </div>

      <div class="grid items-center" style="grid-template-columns: 150px auto">
        <label>
          {{ t('change_profile.last_name') }}
        </label>
        <input
          class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text"
          v-model="userDetails.lastName">
      </div>
      <div class="grid items-center" style="grid-template-columns: 150px auto">
        <label>
          {{ t('change_profile.company') }}
        </label>
        <input
          class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text"
          v-model="userDetails.company">
      </div>
      <div class="grid items-center" style="grid-template-columns: 150px auto">
        <label>
          {{ t('change_profile.address_1') }}
        </label>
        <input
          class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text"
          v-model="userDetails.addressLine1">
      </div>
      <div class="grid items-center" style="grid-template-columns: 150px auto">
        <label>
          {{ t('change_profile.address_2') }}
        </label>
        <input
          class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text"
          v-model="userDetails.addressLine2">
      </div>
      <div class="grid items-center" style="grid-template-columns: 150px auto">
        <label>
          {{ t('change_profile.city') }}
        </label>
        <input
          class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text"
          v-model="userDetails.city">
      </div>
      <div class="grid items-center" style="grid-template-columns: 150px auto">
        <label>
          {{ t('change_profile.postcode') }}
        </label>
        <input
          class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text"
          v-model="userDetails.postcode">
      </div>
      <div class="grid items-center" style="grid-template-columns: 150px auto">
        <label>
          {{ t('change_profile.country') }}
        </label>
        <input
          class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text"
          v-model="userDetails.country">
      </div>
      <div class="grid items-center" style="grid-template-columns: 150px auto">
        <label>
          Phone{{ t('change_profile.phone') }}
        </label>
        <input
          class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="text"
          v-model="userDetails.phone">
      </div>
    </form>

    <div>
      <div class="text-gray-400 text-xs">
        {{ t('change_profile.password') }}
      </div>
      <button
        class="underline mt-2 text-sm"
        @click="$emit('changePwd')">
        {{ t('change_profile.change_my_password') }}
      </button>
    </div>
  </div>
</template>
<script>

import {useI18n} from 'vue-i18n';

export default ({
  name: 'ChangeProfile',
  props: {
    userDetails: Object,
    onDetailsUpdate: Function,
  },

  setup(props, {emit}) {
    const userDetails = props.userDetails;
    const onDetailsUpdate = props.onDetailsUpdate;
    const {t} = useI18n();

    const onProfileSave = async () => {
      await onDetailsUpdate();
      emit('profile');
    }

    return {
      t,
      userDetails,
      onDetailsUpdate,
      onProfileSave,
    };
  },
});

</script>

<style lang="scss" scoped>
</style>
