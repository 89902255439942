<template>
    <div class="flex overflow-hidden">
        <Sidebar/>
        <div class="flex flex-col p-3 w-4/5 h-screen overflow-auto">
          <div class="text-4xl font-bold mt-6 ml-4">{{ t('profile.my_profile') }}</div>

          <Profile
            :class="pending? 'opacity-40 pointer-events-none' : ''"
            :userDetails="getUserDetails()"
            @editProfile="toggleProfile('edit')"
            @changePwd="toggleProfile('changePwd')"
            v-if="showProfile" />

          <ChangeProfile
            :class="pending? 'opacity-40 pointer-events-none' : ''"
            :userDetails="getUserDetails()"
            :onDetailsUpdate="onDetailsUpdate"
            @changePwd="toggleProfile('changePwd')"
            @profile="toggleProfile('profile')"
            v-if="showEditProfile" />

          <ChangePwd
            :class="pending? 'opacity-40 pointer-events-none' : ''"
            :onPasswordChange="onPasswordChange"
            @profile="toggleProfile('profile')"
            v-if="showChangePwd"
          />
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import Sidebar from '@/components/common/Sidebar.vue';
import Profile from '@/components/Profile.vue';
import ChangeProfile from '@/components/ChangeProfile.vue';
import ChangePwd from '@/components/ChangePwd.vue';
import httpClient from '@/services/httpClient';
import {parseJWT} from '@/services/utils';
import TokenService from '@/services/storage';
import {useI18n} from 'vue-i18n';

export default {
  components: {
    Sidebar,
    Profile,
    ChangePwd,
    ChangeProfile
  },
  setup() {
    const showProfile = ref(true);
    const showEditProfile = ref(false);
    const showChangePwd = ref(false);
    const pending = ref();
    const userDetails = ref([]);
    const userId = parseJWT(TokenService.getToken()).data.user.id;
    const {t} = useI18n();


    function toggleProfile(status){
      switch (status) {
        case 'edit':
          showProfile.value = false;
          showEditProfile.value = true;
          showChangePwd.value = false;
          break;
        case 'changePwd':
          showProfile.value = false;
          showEditProfile.value = false;
          showChangePwd.value = true;
          break;
        case 'profile':
          showProfile.value = true;
          showEditProfile.value = false;
          showChangePwd.value = false;
          break;
        default:
          break;
      }
    }

    const onDetailsUpdate = async () => {
      pending.value = true;

      const response = await httpClient.post(
        '/api/v1/dgr/user_details_update', {
          id: userId,
          email: userDetails.value.email,
          displayName: userDetails.value.name,
          firstName: userDetails.value.firstName,
          lastName: userDetails.value.lastName,
          company: userDetails.value.company,
          addressLine1: userDetails.value.addressLine1,
          addressLine2: userDetails.value.addressLine2,
          city: userDetails.value.city,
          postcode: userDetails.value.postcode,
          country: userDetails.value.country,
          phone: userDetails.value.phone,
        });

      pending.value = false;
    }

    const onPasswordChange = async (newPass, oldPass) => {
      pending.value = true;

      const response = await httpClient.post(
        '/api/v1/dgr/password_change', {
          id: userId,
          oldPass,
          newPass,
        }).catch((r) => {
        pending.value = false;
      });

      pending.value = false;
    }

    const fetchDetails = async () => {
      const response = await httpClient.get(
        '/api/v1/dgr/single_customer', {
          query: userId
        });

      userDetails.value = response.data;
    }

    const getUserDetails = () => {
      return userDetails;
    }

    onMounted(async () => {
      await fetchDetails();
    })

    return {
      showEditProfile,
      showChangePwd,
      showProfile,
      pending,
      toggleProfile,
      getUserDetails,
      onDetailsUpdate,
      onPasswordChange,
      t
    }
  }
}

</script>
