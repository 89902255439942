<template>
  <div class="my-8 mx-6 w-2/4 relative shadow-primary p-5 rounded-lg">
    <div class="text-lg font-bold">
      {{ t('change_password.change_password') }}
    </div>

    <form class=" my-6 text-left">
      <label class="text-black text-sm font-bold mt-6">
        {{ t('change_password.current_password') }}
      </label>
      <input
        v-model="oldPass"
        class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="password">

      <label class="text-black text-sm font-bold mt-6">
        {{ t('change_password.new_password') }}
      </label>
      <input
        v-model="newPass"
        class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="password">

      <label class="text-black text-sm font-bold mt-6">
        {{ t('change_password.confirm_new_password') }}
      </label>
      <input
        v-model="newPass2"
        class="w-full h-10 border rounded-xl p-2 my-2 border-gray-200" type="password">

      <button class="w-1/3 h-10 rounded-3xl flex justify-center items-center text-sm font-bold
              bg-primary text-white border border-primary mt-5 mx-auto focus:outline-none
              hover:bg-white hover:text-primary hover:shadow-lg"
              @click="onPassword">
        {{ t('change_password.save') }}
      </button>
    </form>
  </div>
</template>
<script>

import {ref} from 'vue';
import { useI18n } from 'vue-i18n';

export default ({
  name: 'ChangePwd',
  props: {
    userDetails: Object,
    onPasswordChange: Function,
  },

  setup(props, {emit}) {
    const {t} = useI18n();
    const userDetails = props.userDetails;
    const onPasswordChange = props.onPasswordChange;
    const newPass = ref();
    const oldPass = ref();
    const newPass2 = ref();

    const onPassword = async () => {
      await onPasswordChange(newPass.value, oldPass.value);
      emit('profile');
    }

    return {
      t,
      userDetails,
      onPassword,
      newPass,
      newPass2,
      oldPass,
    };
  },
});

</script>

<style lang="scss" scoped>
</style>
