<template>
  <div class="my-8 mx-6 w-2/4 relative shadow-primary p-5 rounded-lg">
    <div class="flex justify-between items-center text-lg font-bold mb-4">
      <span>
        {{ t('profile.personal_details') }}
      </span>
      <button class="underline text-gray-400 text-xs" @click="$emit('editProfile')">
        {{ t('profile.edit_details') }}
      </button>
    </div>

    <div class="flex justify-between items-enter mb-4">
      <span class="text-gray-400 text-xs">
        {{ t('profile.account_name') }}
      </span>
      <span class="text-sm my-2">
        {{ userDetails.name }}
      </span>
    </div>

    <div class="flex justify-between items-enter mb-4">
      <span class="text-gray-400 text-xs">
        {{ t('profile.email_address') }}
      </span>
      <span class="text-sm my-2">
        {{ userDetails.email }}
      </span>
    </div>

    <div class="flex justify-between items-enter mb-4">
      <span class="text-gray-400 text-xs">
        {{ t('profile.languages') }}
      </span>
      <span class="text-sm my-2">
          <Dropdown
            class="w-full"
            v-model="locale"
            :options="langs"
            optionValue="value"
            optionLabel="name"/>
      </span>
    </div>

    <div class="flex justify-between items-enter mb-4">
      <span class="text-gray-400 text-xs">
        {{ t('profile.password') }}
      </span>
      <button
        class="underline mt-2 text-sm"
        @click="$emit('changePwd')">
        {{ t('profile.change_password') }}
      </button>
    </div>
  </div>
</template>
<script>

import {useI18n} from 'vue-i18n';

export default ({
  name: 'Profile',
  props: {
    userDetails: Object,
  },

  setup(props) {
    const {t , locale } = useI18n();
    const userDetails = props.userDetails;
    const langs = [
      { name: 'English', value: 'en'},
      { name: 'German', value: 'de'}
    ];
    return {
      t,
      userDetails,
      locale,
      langs
    };
  },
});

</script>

<style lang="scss" scoped>
</style>
